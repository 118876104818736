import React from 'react';

export default function Footer(props)   {  

    return (
            
            <footer className="bd-footer text-muted">
            <div className="container-fluid p-3 p-md-5">
                <hr/>
                <img src="/assets/img/upcut tests knowledge.png" alt="upcut tests knowledge"/>
                <p>
                    <small>&copy; upcut. All rights reserved.</small>
                </p>
            </div>
            </footer>
        );
    }
    