import React from "react";
import QAs from './QAs';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';


export default function Set(props) {

    const QBSet = props.QBSet
     

  return (
      <React.Fragment>   
            <h4 className="text-muted">{QBSet.title}</h4>         
            <h5 className="text-muted">{QBSet.description}</h5>
            <h5 className="text-muted">{QBSet.tags}</h5>
            <hr />
            <Container>

                <Row>
                  <QAs QBSet = {QBSet}/>
                </Row>
                <hr />

            
            </Container>  
      </React.Fragment>

  );
}