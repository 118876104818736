import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import About from './components/about'
import Home from './components/home'
import Test from './components/test'
import User from './components/user'
import Footer from "./features/frame/Footer"
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { LinkContainer } from 'react-router-bootstrap'
import UserName from './features/user/UserName'


function AppRouter() {
    return (
      <Router>

        <Navbar expand="lg" navbar-light="true" bg="light">

          <Navbar.Brand href="/">
            <img src="/assets/img/upcut logo.png" height="50" alt="upcut logo"/>
          </Navbar.Brand> 
          <Nav.Link ><UserName/></Nav.Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <LinkContainer activeClassName = "active" exact= { true } to="/">
                <Nav.Link >Home</Nav.Link>
              </LinkContainer>
              <LinkContainer activeClassName = "active" exact= { true } to="/about/">
                <Nav.Link >About</Nav.Link>
              </LinkContainer>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Switch>
          <Route path="/about/" component={About} />
          <Route path="/test/:url" component={Test} /> 
          <Route path="/user/:url" component={User} />          
          <Route path="/" exact component={Home} />
        </Switch>
        < Footer />
      </Router>
    );
  }
  
  export default AppRouter;