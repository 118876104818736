import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useDispatch, useSelector } from 'react-redux';

import {testUser, createAttempt} from './userSlice'


export default function User(props) {

  const dispatch = useDispatch();

  const currentUserId = useSelector((state) => state.user.currentUserId)
  const user = useSelector((state) => state.user.users[currentUserId])
  const QBSet = useSelector((state) => state.QB.initialQB["2xTable"])
    

  return (
      <React.Fragment>
            <h4 className="text-muted">Id: {user.userId}</h4>
            <h4 className="text-muted">{user.firstName + " " +user.lastName}</h4>

            <hr />
            <Container>
                <Row>
                  Attempts
                </Row>
      
            </Container>  
            <button onClick = {() => dispatch(testUser())}>Test</button>
            <button onClick = {() => dispatch(createAttempt(QBSet))}>Create Attempt</button>
      </React.Fragment>

  );
}