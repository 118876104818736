import React from "react";
import {SPELLING} from './QBType.js'

export default function Question(props) {

    if (props.QBSet.type === SPELLING && props.showQuestion===false) {
        return  <h2 className='blur'>{props.qa.question}</h2>
    } else {
        return  <h2>{props.qa.question}</h2>
    }
}