import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {UNANSWERED, CORRECT, INCORRECT} from '../user/MarkTypes';
import {INPROGRESS, COMPLETE} from '../user/AttemptStatusTypes';


function renderSwitch(props) {
    switch (props.mark) {

        case CORRECT:
            return (
                <Row>
                    <Col>
                     <img src="/assets/icons/check.svg" alt=""></img>
                    </Col>
                    <Col>
                    </Col>
                </Row>
            )
        case INCORRECT||UNANSWERED:
            return (
                <Row>
                    <Col>
                        <img src="/assets/icons/x.svg" alt=""></img>
                    </Col>
                    <Col>
                        answer={props.qa.answer}
                    </Col>
                </Row>
            )
        default:
            return '';
    };
};



export default function Mark(props) {

    if (props.QBSet.status === COMPLETE) {
        return (
            <Container>
                    {renderSwitch(props)}
            </Container>
        );
    }
    return ''

};