import React from 'react';
import Attempt from '../features/QB/Attempt';
import {useParams} from 'react-router-dom';
import Menu from '../features/navigation/menu/Menu'


export default function Test() {


    let { url } = useParams();

    return (
        <React.Fragment>
        <div className="container">

              <Attempt setName ={url}/>
        </div>

    </React.Fragment>

  
    );
  }

