import React, {useState} from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Question from './Question';
import MyAnswer from './MyAnswer';
import Mark from './Mark';






export default function QA(props) {

    const [showQuestion, setShowQuestion] = useState(true);


 return (
      <Container>
      <Row>
        <Col>
            <Question index={props.index}  QBSet = {props.QBSet} qa = {props.qa}
                showQuestion = {showQuestion}/>
        </Col>
        <Col>
            <MyAnswer index={props.index} QBSet = {props.QBSet} 
                qa = {props.qa} setShowQuestion = {setShowQuestion}/>
        </Col> 
        <Col>
            <Mark mark={props.qa.mark} QBSet = {props.QBSet}  qa = {props.qa}/>
        </Col>  

    </Row>
    </Container>

  );
}





