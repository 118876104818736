import React from 'react';

export default function About (props) {
    
        return (
            <React.Fragment>
                <div className="container">
                <h2>upcut tests knowledge</h2>
                <p>Test your maths and spelling knowledge with upcut.</p>

                </div>
            </React.Fragment>
        );
    }
