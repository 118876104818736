import React from "react";
import { useSelector } from 'react-redux';


export default function UserName(props) {

  const currentUserId = useSelector((state) => state.user.currentUserId)
  const user = useSelector((state) => state.user.users[currentUserId])

    

  return (
      <React.Fragment>

            <h6 className="text-muted">{user.firstName + " " +user.lastName}</h6>

      </React.Fragment>

  );
}