import { createSlice } from '@reduxjs/toolkit';
import {INPROGRESS, COMPLETE} from '../user/AttemptStatusTypes';
import {UNANSWERED, CORRECT, INCORRECT} from './MarkTypes';


const user = require('../../store/json/User.json');


export const userSlice = createSlice({
  name: 'user',
  initialState: user,
  reducers: {
    testUser (state, action)  {
      state.users[0].firstName = "Geoff";
    },
    createAttempt (state, action) {
      const currentUserId = action.payload.currentUserId
      const currentUser = state.users[currentUserId]
      const QBSet = action.payload.attemptSet
      QBSet.status = INPROGRESS
      QBSet.startTime = Date.now()

      currentUser.attempts.push(
        {
        QBSet: QBSet
        }
      )
      currentUser.currentAttemptId=currentUser.attempts.length-1
      currentUser.attemptFocus = true

    },
    addMyAnswer(state, action){
      const QA = state.users[0].attempts[1].QBSet.QAs[action.payload.index]
      QA.myAnswer = action.payload.myAnswer
      QA.mark = setMark(QA.answer, QA.myAnswer)

    },
    submitAnswers (state, action) {
      const currentUserId = action.payload.currentUserId
      const currentUser = state.users[currentUserId]
      const QBSet = state.users[0].attempts[1].QBSet
      QBSet.endTime = Date.now()
      QBSet.status = COMPLETE   
    }
  }
});

export const { testUser, createAttempt, addMyAnswer, submitAnswers} = userSlice.actions;

export const selectUser =
       state => state.users[0];

const setMark = (answer, myAnswer) =>
  {
      if (myAnswer==='') {
          return UNANSWERED;}
      if (myAnswer===answer.toString()) {
          return CORRECT;}
      return INCORRECT;
      };



export default userSlice.reducer;


