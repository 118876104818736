import React from "react";
import QA from "./QA";



export default function QAs(props) {

    return (
        props.QBSet.QAs.map((qa, index) => 
                <QA key={qa.question} index={index} qa={qa} QBSet = {props.QBSet}/>
        )
    )
};



