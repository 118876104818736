import React from 'react';
import User from '../features/user/User';
import {useParams} from 'react-router-dom';
import Menu from '../features/navigation/menu/Menu'


export default function Test() {


    let { url } = useParams();

    return (
        <React.Fragment>
        <div className="container">
        <Menu />
        <h4> User Page </h4>
              <User url ={url}/>
        </div>

    </React.Fragment>

  
    );
  }