import React from "react";
import Container from 'react-bootstrap/Container';

import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';


export default function Result(props) {

  const QAs = props.QBSet.QAs

  const length = QAs.length
  const seconds = Math.round((props.QBSet.endTime - props.QBSet.startTime)/1000*10)/10


  const resultDescription = 
  <Container className="Result">
  <Row>
        <h4>You scored {QAs.filter(x => x.mark==="CORRECT").length} out of {length}</h4>
  </Row>
  <Row>
        <h4>It took {seconds} seconds to complete this test.</h4>
    </Row>  
    <Row>
      <Button onClick={(e) => {
        e.preventDefault();
        window.location.href='/';
        }}>Close Test</Button>    
    </Row>    
  </Container>;


 return resultDescription;

}