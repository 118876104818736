import React, {useState} from "react";
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { useDispatch } from 'react-redux';
import { addMyAnswer } from '../user/userSlice';



export default function MyAnswer(props) {

    const setShowQuestion = props.setShowQuestion;

    const dispatch = useDispatch()

    const [isFirstAfterFocus, setIsFirstAfterFocus] = useState(false);

    const setMyAnswer = (myAnswer, index) =>{
        console.log("Set myAnswer "+myAnswer);
        dispatch(addMyAnswer({index: index, myAnswer: myAnswer }));
        setShowQuestion(false);
        }

    const onChange = (e) => {
            setMyAnswer(e.target.value, props.index);
            if (isFirstAfterFocus) {
                setShowQuestion(false)
                setIsFirstAfterFocus (false);
            }
        }

    const onFocus = () => {
        setIsFirstAfterFocus (true);
    }
        
       return (
        <InputGroup className="mb-3">
                <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">
                    <img src="/assets/icons/arrow-right.svg" alt=""></img>
                </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                aria-label="Attempt"
                aria-describedby="basic-addon1"
                onChange={(e) => onChange(e)}
                onFocus={(e) => onFocus()}
                spellcheck="false"
                />
        </InputGroup>
       ) 

}