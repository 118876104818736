import React from "react";
import Button from 'react-bootstrap/Button';



export default function StartTest (props) {  


    return (
    <Button onClick={props.handleStartTest}>{"Start Test: "+props.QBSet.title}</Button>
    )


}