import React from 'react';

import Menu from '../features/navigation/menu/Menu';



export default function Home(props) {
    
        return (
            <React.Fragment>

            <Menu />         
        </React.Fragment>        
        );
    }