import React from "react";
import MenuItem from "./MenuItem";
import { useSelector } from 'react-redux';



export default function Menu(props) {

    const menuItems = 
    useSelector(state => state.Menu.initialMenu.tests);
        

    return (
            <ul class="list-group">
                {menuItems.map(
                    (menuItem) => 
                        <MenuItem key={menuItem.id} menuItem={menuItem} />
                    )}
            </ul>

    )
};