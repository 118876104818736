import React from "react";

import AttemptHeader from "./AttemptHeader.js"
import AttemptBody from "./AttemptBody.js"
import AttemptFooter from "./AttemptFooter.js"

import {useDispatch, useSelector} from 'react-redux';

import {createAttempt} from '../user/userSlice'


export default function Attempt(props) {

    

    const currentUserId = useSelector((state) => state.user.currentUserId)
    const user = useSelector((state) => state.user.users[currentUserId])
    const sourceSet = useSelector((state) => state.QB.initialQB[props.setName])

    const attempts = useSelector((state) => user.attempts)
    const attemptSet = useSelector((state) => attempts[attempts.length-1].QBSet)
    const attemptFocus = useSelector((state) => user.attemptFocus)

    const dispatch = useDispatch()


    function HandleStartTest()  {       
    
        const generateRandomQA = (sourceSet) => {
            const QAsRandom = [...sourceSet.QAs].sort(() => 0.5 - Math.random())
            const QAsRandom1 = QAsRandom.map(item => {
                return {...item, mark : "UNANSWERED", myAnswer : ''};
            })
            const QBSetRandom = Object.assign({}, sourceSet)
            QBSetRandom.QAs = QAsRandom1
            return QBSetRandom
        }
        
        
    
        const preparePayload = 
        {
                  currentUserId: currentUserId,
                  startTime: "time",
                  attemptSet: generateRandomQA(sourceSet)
              }    
    
        console.log("Start Test")
        dispatch(createAttempt(preparePayload));
    }

     

     const renderTest = () =>
    {
        
        if (attemptFocus===true){
            return (
                <React.Fragment>
                    <AttemptBody QBSet={attemptSet}/>
                    <AttemptFooter QBSet={attemptSet} />
                 </React.Fragment>
            )
        } 
    else
    {
        return <AttemptHeader QBSet={sourceSet} handleStartTest={HandleStartTest}/>
    }
    } 


    return (
        <React.Fragment>         

            {renderTest()}

        </React.Fragment>
    )




}

