import React from "react";
import StartTest from './StartTest.js';
import Jumbotron from 'react-bootstrap/Jumbotron';


export default function AttemptHeader (props) {
   
    return (

        <Jumbotron>
        <div className="container">
            <div className="row">
                <div className="col-sm">
                    <p className="lead">
                    <StartTest QBSet={props.QBSet}  handleStartTest={props.handleStartTest}/> 
                    </p>
                </div>
            </div>
        </div>
    </Jumbotron>   

        
    )

}