import React from "react";
import Result from './Result.js';
import Submit from './Submit.js';
import {INPROGRESS, COMPLETE} from '../user/AttemptStatusTypes';


export default function AttemptFooter (props) {
    
    const QBSet = props.QBSet;

    if (QBSet.status  === COMPLETE) {
         return <Result QBSet={QBSet}/> 
    }
    else return  <Submit QBSet={QBSet}/> 

}