import React from "react";
import {submitAnswers} from '../user/userSlice';
import {useDispatch, useSelector} from 'react-redux';
import Button from 'react-bootstrap/Button';

export default function Submit (props) {

    const dispatch = useDispatch()

     const currentUserId = useSelector((state) => state.user.currentUserId)

    const preparePayload = 
    {
              currentUserId: currentUserId,
              QBSet: props.QBSet
          }

    function handleSubmitAnswers()
    {   
        console.log("Submit Answers")
        dispatch(submitAnswers(preparePayload));
    }

    const submitAnswersButton = 
        <Button onClick={handleSubmitAnswers}>Submit Answers</Button>;       

    return submitAnswersButton
}

