import { createSlice } from '@reduxjs/toolkit';

const initialMenu = require('../../../store/json/Menu.json');

export const MenuSlice = createSlice({
  name: 'Menu',
  initialState: {
    initialMenu,
  },
  reducers: {
  },
});


export default MenuSlice.reducer;
