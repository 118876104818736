import { createSlice } from '@reduxjs/toolkit';

const initialQB = require('../../store/json/QBSets.json');

export const QBSlice = createSlice({
  name: 'QB',
  initialState: {
    initialQB,
  },
  reducers: {
  },
});




export default QBSlice.reducer;
