import { configureStore } from '@reduxjs/toolkit';
import QBSlice from '../features/QB/QBSlice';
import MenuSlice from '../features/navigation/menu/MenuSlice';
import userSlice from '../features/user/userSlice';



export default configureStore({
  reducer: {
    QB: QBSlice,
    Menu: MenuSlice,
    user: userSlice
  },
});
