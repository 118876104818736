import React from "react";
import Set from "./Set";



export default function AttemptBody(props) {

    return (
        <Set {...props}/>
    )

}