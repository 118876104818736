import React from "react";



export default function MenuItem(props) {

    return (
        <li class="list-group-item">
            <a href={"test/"+props.menuItem.url} >{props.menuItem.title}</a>
        </li>

    )
};