import { tsPropertySignature } from "@babel/types";
import React from "react";
import MyAnswerEdit from './MyAnswerEdit.js'
import {INPROGRESS, COMPLETE} from '../user/AttemptStatusTypes';




export default function MyAnswer(props) {

    if (props.QBSet.status === INPROGRESS) {
        return   <MyAnswerEdit index = {props.index} setShowQuestion = {props.setShowQuestion}/>
    } else {
        return  <p>{props.qa.myAnswer}</p>
    }
}